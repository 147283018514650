import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { GlobalHeader, GlobalFooter } from '@/components/Layouts';
import { useConfigProvider } from '@/context/ConfigProvider';
import classNames from 'classnames';
import { AppSettings } from '@/shared/app-common';
import { useTranslation } from 'next-i18next';
import { EventName } from '@/shared/event-types';
import { useRouter } from 'next/router';
import { imgUrl } from '@/shared/utils';
import LeftFolder from '@/components/leftFolder/index';

interface LayoutProps {
  /**
   * Please use next/head
   */
  head?: React.ReactNode;
  children: React.ReactNode;
  showHeader?: boolean;
  showFooter?: boolean;
}

interface TdkData {
  title: string;
  description: string;
  keywords: string;
}

const Layout = ({ children, head, showHeader = true }: LayoutProps) => {
  const router = useRouter();
  const { isMobile } = useConfigProvider();
  const { t } = useTranslation('common');
  const ref = useRef(null);
  const isShowHead = useCallback(() => {
    if (
      [
        '/dashboard',
        '/profile',
        '/safe-links',
        '/setting',
        '/leaks-found',
        '/protected-Accounts',
        '/social-media',
      ].includes(router.pathname) &&
      !isMobile
    ) {
      return false;
    } else {
      return true;
    }
  }, [router.pathname, isMobile]);

  const isShowBg = useCallback(() => {
    if (
      [
        '/dashboard',
        '/profile',
        '/safe-links',
        '/setting',
        '/dmca-protection-badge',
        '/leaks-found',
        '/protected-Accounts',
        '/social-media',
      ].indexOf(router.pathname) != -1 &&
      isMobile
    ) {
      return true;
    } else {
      if (
        router.pathname.includes('/compare') ||
        router.pathname.includes('/content-monitoring') ||
        router.pathname.includes('/dmca-takedown') ||
        router.pathname.includes('/guide') ||
        router.pathname.includes('/alternatives')
      ) {
        return true;
      } else {
        return false;
      }
    }
  }, [router.pathname, isMobile]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (ref.current) {
            }
            observer.disconnect();
          }
        });
      },
      { threshold: 0.1 },
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [router.pathname.includes('starData'), showHeader]);
  const getNavItems = () => {
    switch (AppSettings.type) {
      case '16':
        return [
          {
            text: t('Solution'),
            path: `/onlyfans-protection`,
            gaName: EventName.header_service,
            dropdownItems: [
              {
                text: t('OnlyFans Content Protection'),
                path: `/onlyfans-protection`,
                gaName: EventName.header_onlyfans_protection,
              },
              {
                text: t('Impersonation Account Removal'),
                path: `/remove-fake-account`,
                gaName: EventName.header_remove_fake_account,
              },
              {
                text: t('Cam Model Protection'),
                path: `/cam-model-protection`,
                gaName: EventName.header_cam_model_protection,
              },
            ],
          },
          {
            text: t('Service'),
            path: `/content-monitoring`,
            gaName: EventName.header_service,
            dropdownItems: [
              {
                text: t('Content Monitoring'),
                path: `/content-monitoring`,
                gaName: EventName.header_onlyfans_protection,
              },
              {
                text: t('DMCA Takedown'),
                path: `/dmca-takedown`,
                gaName: EventName.header_remove_fake_account,
              },
            ],
          },
          {
            text: t('Pricing'),
            path: '/plan',
            gaName: EventName.header_Plan,
          },
          {
            text: t('Resources'),
            path: `/dmca-protection-badge`,
            gaName: EventName.header_tool,
            dropdownItems: [
              {
                text: t('Blog'),
                path: `/blog`,
                gaName: EventName.header_tool,
              },
              {
                text: t('DMCA Badges'),
                path: `/dmca-protection-badge`,
                gaName: EventName.header_tool,
              },
              {
                text: t('Guides'),
                path: `/guide`,
                gaName: EventName.header_tool,
              },
              {
                text: t('OnlyFans Restricted Words Checker'),
                path: `/onlyfans-restricted-words-checker`,
                gaName: EventName.header_tool,
              },
              {
                text: t('OnlyFans Caption Generator'),
                path: `/onlyfans-caption-generator`,
                gaName: EventName.header_tool,
              },
              {
                text: t('Platform Comparison'),
                path: `/compare`,
                gaName: EventName.header_tool,
              },
            ],
          },
          {
            text: t('Affiliate Program'),
            path: `/affiliate`,
            gaName: EventName.header_affiliate,
          },
        ];

      default:
        [{}];
    }
  };

  const isShowLeft = () => {
    let urlArr = [
      '/dashboard',
      '/leaks-found',
      '/safe-links',
      '/protected-Accounts',
      '/setting',
      '/profile',
      '/social-media',
    ];
    if (urlArr.indexOf(router.pathname) == -1) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div
      className={'layout'}
      style={{
        backgroundSize: router.pathname == '/' ? ' contain' : 'cover',
        backgroundImage: !isShowBg()
          ? `url(${imgUrl('https://res-front.pumpsoul.com/prod-erasa/image/background-icon.png', 'big')})`
          : '',
      }}
    >
      {head}
      {showHeader && isShowHead() && (
        <header className={'header'}>
          <GlobalHeader isMobile={isMobile} navItems={[...getNavItems()]} />
        </header>
      )}
      <main
        ref={ref}
        style={{
          marginTop: !isShowHead() ? 0 : 'calc(var(--pietra-global-header-heigth))',
        }}
        className={classNames('main', {
          mobile: isMobile,
        })}
      >
        <div>
          {isShowLeft() && <LeftFolder></LeftFolder>}
          <div>{children}</div>
        </div>
      </main>
      {isShowHead() && (
        <footer className={'footer'}>
          <GlobalFooter />
        </footer>
      )}
    </div>
  );
};
export default Layout;
